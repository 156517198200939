import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index';
import Notifications from 'vue-notification';
import VueConfirmDialog from 'vue-confirm-dialog';
import moment from 'moment';

Vue.config.productionTip = false
Vue.use(Notifications);
Vue.use(VueConfirmDialog);
Vue.filter('formatDateTime', function(value){
  if (value) {
    return moment(String(value)).format('MMMM Do YYYY, h:mm:ss a')
  }
})
Vue.filter('formatDate', function(value){
  if (value) {
    return moment(String(value)).format('MMMM Do YYYY')
  }
})
Vue.mixin({
  methods: {
    isAuthenticated() {
      const user = this.$store.state.user;
      return user != null;
    },
    isAdmin() {
      const user = this.$store.state.user;

      if (user == null || user.profile == null) {
        return false;
      }
      return user.profile.role == 1;
    }
  },
})

// Get client settings
fetch(`${process.env.VUE_APP_BACKEND_URL}/client`)
.then(response => response.json())
.then((data) => {
  // Set client id and create user manager client
  store.dispatch('setClientIdAction', data.clientId);
    
  // Create vue app once we have the config settings
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app');
});

import Vue from 'vue'
import Vuex from 'vuex'
import { security } from '../shared/security.js';
import { UPDATE_CURRENT_USER, UPDATE_USER_MANAGER } from './mutation-types';

Vue.use(Vuex)

const state = {
    user: null,
    mgr: null
  };
  
  const mutations = {
    [UPDATE_CURRENT_USER](state, user) {
      state.user = user;
    },
    [UPDATE_USER_MANAGER](state, userManager) {
      state.mgr = userManager;
    }
  };
  
  const actions = {
    async setUserAction({ commit }, user) {
      // Save user details
      commit(UPDATE_CURRENT_USER, user);
    },
    async setClientIdAction({ commit }, clientId) {
      var userManager = security.createUserManager(clientId);
  
      commit(UPDATE_USER_MANAGER, userManager);
    }
  };
  
  const modules = {};
  
  const getters = {};

export default new Vuex.Store({
  state,
  mutations,
  actions,
  modules,
  getters
})
